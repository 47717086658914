import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import SEO from "../components/SEO";

const Home = ({ data }) => {

    // console.log(data);
    const introduction = data.introduction.nodes;
    const introduction_vi = data.introduction_vi.nodes;
    const what_we_do = data.what_we_do.nodes;
    const what_we_do_vi = data.what_we_do_vi.nodes;
    const who_we_are = data.who_we_are.nodes;
    const who_we_are_vi = data.who_we_are_vi.nodes;
    const who_cooperates_with_us = data.who_cooperates_with_us.nodes;
    const who_cooperates_with_us_vi = data.who_cooperates_with_us_vi.nodes;
    const who_cooperates_with_us_img = data.who_cooperates_with_us_img.nodes;

    return (
        <Layout>
            <SEO title="Home" description="Appvity System Vietnam (ASV) provide collaboration tools such as task management, helpdesk system that run on Microsoft Teams to improve productivity for enterprise. Building app based on Microsoft Azure, Microsoft O365, SharePoint, Microsoft Power Automate and integrated with Microsoft Teams." meta={[{ name: `keywords`, content: ["collaboration app", "task management on Microsoft Teams", "integrate with Microsoft Teams", "run on Microsoft Teams", "productivity"] }]} pathname="/" />
            <div className="main-content">
                <div className="app-general-seciton app-home-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
                            <Col>
                                {introduction.map((res) => (
                                    <div className="app-data-en app-home-introduction-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                ))}
                                {introduction_vi.map((res) => (
                                    <div className="app-data-vi app-home-introduction-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                ))}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="app-general-seciton app-section-what-we-do" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <div className="app-section-what-we-do-detail">
                            <Row>
                                <Col xs={12} md={12} lg={5}>
                                    {what_we_do.map((res) => (
                                        <div className="app-data-en app-what-we-do-detail-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    {what_we_do_vi.map((res) => (
                                        <div className="app-data-vi app-what-we-do-detail-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={1}></Col>
                                <Col xs={12} md={12} lg={6}>
                                    <div className="app-what-we-do-detail-right">
                                        <img src="/app-section-what-we-do.jpg" alt="app section what we do" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="app-general-seciton app-section-who-we-are" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        {who_we_are.map((res) => (
                            <div className="app-data-en app-who-we-are-content" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                        ))}
                        {who_we_are_vi.map((res) => (
                            <div className="app-data-vi app-who-we-are-content" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                        ))}
                    </Container>
                </div>
                <div className="app-general-seciton app-section-who-cooperates-with-us" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={2}></Col>
                            <Col xs={12} md={12} lg={8}>
                                <div className="app-section-who-cooperates-with-us-detail">
                                    {who_cooperates_with_us.map((res) => (
                                        <div className="app-data-en app-who-cooperates-with-us-title-description" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    {who_cooperates_with_us_vi.map((res) => (
                                        <div className="app-data-vi app-who-cooperates-with-us-title-description" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    <div className="app-section-who-cooperates-with-us-img">
                                        <Row>
                                            {who_cooperates_with_us_img.map((res) => (
                                                <Col xs={6} md={4} lg={4} xl={3} key={res.id}>
                                                    <div className="app-section-who-cooperates-with-us-img-body" title={res.frontmatter.title} dangerouslySetInnerHTML={{ __html: res.html }} />
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={2}></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </Layout>
    );
};

export default Home;

export const query = graphql`
    query SiteInfo {
        site {
            siteMetadata {
                copyright
                description
                title
            }
        }
        introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "home_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        introduction_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "home_introduction_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        what_we_do: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "home_what_we_do" } } }
        ) {
            nodes {
                html
                id
            }
        }
        what_we_do_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "home_what_we_do_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        who_we_are: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "home_who_we_are" } } }
        ) {
            nodes {
                html
                id
            }
        }
        who_we_are_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "home_who_we_are_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        who_cooperates_with_us: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "home_who_cooperates_with_us" } } }
        ) {
            nodes {
                html
                id
            }
        }
        who_cooperates_with_us_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "home_who_cooperates_with_us_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        who_cooperates_with_us_img: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "home_who_cooperates_with_us_img" } } }
            sort: { fields: frontmatter___createAt, order: ASC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
    }
`;
